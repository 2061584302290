#master-footer {
  padding: 15px;
  background-color: #f5f1eb;
}
#master-footer .master-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "copy social links";
  color: var(--color-palette-dark-blue);
  font-size: 18px;
  align-items: center;
}
#master-footer p {
  grid-area: copy;
}
#master-footer ul {
  grid-area: social;
  list-style: none;
}
#master-footer ul li {
  display: inline-block;
  margin: 0 15px;
}
#master-footer ul li a {
  text-decoration: none;
  color: inherit;
}
#master-footer nav a {
  display: inline-block;
  margin: 0 15px;
  text-decoration: none;
}
@media screen and (max-width: 880px) {
  #master-footer .master-container {
    grid-template-columns: 1fr;
    grid-template-areas: "social" "links" "copy";
  }
  #master-footer .master-container ul {
    padding: 0;
    margin: auto;
    text-align: center;
  }
  #master-footer .master-container ul li {
    margin: 0 10px;
  }
  #master-footer .master-container nav {
    text-align: center;
  }
  #master-footer .master-container nav a {
    margin: 15px;
  }
}
